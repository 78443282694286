:root {
  --primary-color: #000000;
  --secondary-color: #eaf0ed;
  --accent-color-dark: #346498;
  --accent-color: #6193c9;
  --accent-pale: #a8c2e0;
  --background-color: #f8f8ff;
  --text-color: #000000;
  --error-color: #FF0000; 
  --error-color-dark: #DD0000; 
  --negative-color: #000000;
  --light-negative-color: #202020;

  --header-height: 80px; 
  --footer-height: 60px;
  /* uses calculation inside theme to correctly set footer and headder  */
  --router-height: calc(100*var(--actual-vh) - var(--header-height) - var(--footer-height));
}

@media (max-width: 768px) {
  :root {
    --header-height: 60px;  /* Smaller header for mobile */
    --footer-height: 40px;  /* Smaller footer for mobile */
    --router-height: calc(100*var(--actual-vh) - var(--header-height) - var(--footer-height));
  }
}

/* Global CSS */
/* Set the background color of the scrollbar track */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the scrollbar track to transparent */
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

html {
  scroll-behavior: smooth;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: var(--router-height);
  margin-top: var(--header-height); /* Sets the top margin to the height of the header */
  overflow-y: auto;
}


.router-content {
  overflow-y: auto;
  flex: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
